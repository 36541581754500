import React from "react"
import { Router, Redirect } from "@reach/router"
import { useEffect } from "react"
import { navigate } from "gatsby"

const IndexPage = () => {
  useEffect(() => {
    navigate('https://trendwatching.com/make-shift');
  }, []);
  return null;
}

export default IndexPage
